import {CSSTransition} from 'react-transition-group'
import {toAbsoluteUrl} from '../../../_gori/helpers'
import {Footer} from './components/Footer'
import {Header} from './components/Header'
import {useIntl} from 'react-intl'

export function ServiceTermsPage() {
  const intl = useIntl()
  return (
    <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
      <>
        <Header />

        {/* tittle */}
        <div className='mt-20'>
          <div className='container mt-20'>
            <div className='justify-content-end d-flex'>
              <img
                src={toAbsoluteUrl('/media/gori/policies/check.png')}
                className='w-90px me-20'
                alt=''
              />
            </div>
            <div className='justify-content-center d-flex'>
              <div className='text-center display-2 fw-bolder pb-12' style={{color: '#16BAA8'}}>
                Terms of Service
              </div>
            </div>
            <div className='justify-content-start d-flex'>
              <img
                src={toAbsoluteUrl('/media/gori/policies/truck.png')}
                className='w-200px mx-5'
                alt=''
              />
            </div>
          </div>
        </div>

        {/* policy */}
        <div className='bg-white py-20'>
          <div className='container mb-10'>
            <div className='mt-5 pb-6'>
              <h3 className='pb-3'>Service Fees and Payments.</h3>
              <p>
                You are agreeing to subscribe to {intl.formatMessage({id: 'BRAND_NAME'})} Service on
                a monthly basis. Your subscription will continue and renew automatically unless
                terminated as per the "Termination" section below. You agree to pay all charges for
                the current billing period at the time of cancellation, whether or not the current
                billing period is only partially complete. All fees and charges are nonrefundable.{' '}
                {intl.formatMessage({id: 'BRAND_NAME'})} may change the Service Fees then in effect,
                or add new fees or charges, by giving You notice in advance. Continued use of the
                Service after any such fee changes shall constitute your consent to the new fees.
              </p>
            </div>
            <div className='pb-6'>
              <h3 className='pb-3'>90 Day Satisfaction Guarantee.</h3>
              <p>
                During the first 30 days when you initially signed up for your account{' '}
                {intl.formatMessage({id: 'BRAND_NAME'})}
                provides a satisfaction guarantee and will issue refunds of all software service
                fees incurred during the initial 30 day window. You must request this refund in
                writing within the first 30 day window, as part of your Termination request. This
                guarantee only covers actual software service fees, and do not cover "insurance" and
                "postage" purchased through {intl.formatMessage({id: 'BRAND_NAME'})}.
              </p>
            </div>
            <div className='pb-6'>
              <h3 className='pb-3'>Postage & Payments.</h3>
              <p>
                You may use certain " {intl.formatMessage({id: 'BRAND_NAME'})} Provided" carrier
                account, like USPS, DHL Express, USPS Presort, etc, where{' '}
                {intl.formatMessage({id: 'BRAND_NAME'})} maintains a "postage meter" so you can
                pre-pay postage that will be deducted when you print shipping labels. You agree that{' '}
                {intl.formatMessage({id: 'BRAND_NAME'})} will deduct from your postage cost of any
                printed postage, and any surcharges that the carrier may later issue for those
                labels. {intl.formatMessage({id: 'BRAND_NAME'})}
                postage meter may be paid using bank transfer, credit cards, PayPal and other
                payment methods that {intl.formatMessage({id: 'BRAND_NAME'})} may add in the future,
                subject to certain limits, and you agree to maintain at least one valid payment
                option at all times the postage meter is active. You agree that{' '}
                {intl.formatMessage({id: 'BRAND_NAME'})} may place limits on the amount that can
                deposit into your postage meter or the payment method used, You also agree that if
                your {intl.formatMessage({id: 'BRAND_NAME'})} Postage Meter balance reaches negative
                for any reason, that {intl.formatMessage({id: 'BRAND_NAME'})} has the right to
                charge your main or any backup payment method amount needed to cover the shortage.
              </p>
            </div>
            <div className='pb-6'>
              <h3 className='pb-3'>Postage Meter Registration and Guarantee of Payment.</h3>
              <p>
                You agree to provide your authentic name, company, address and contact information
                when you register your {intl.formatMessage({id: 'BRAND_NAME'})} Postage Meter, and
                have such information transmitted to the carriers. You agree to pay and be hold
                liable for any sur-charges that the carriers may issue, on the labels you printed
                through your {intl.formatMessage({id: 'BRAND_NAME'})}
                postage meter, including any penalties, as part of under reporting of weight, size,
                or as part of other carrier rules.
              </p>
            </div>
            <div className='pb-6'>
              <h3 className='pb-3'>Postage Payment Fees.</h3>
              <p>
                {intl.formatMessage({id: 'BRAND_NAME'})} allows ACH Payment without Fees. Subject to
                a certain limit,
                {intl.formatMessage({id: 'BRAND_NAME'})} allow US credit card payments as postage
                without fees. Other payment methods, like PayPal, etc, may carry fees imposed by the
                provider of the payment method. In those cases,{' '}
                {intl.formatMessage({id: 'BRAND_NAME'})} will credit to your postage meter the net
                amounts after fees.
              </p>
            </div>
            <div className='pb-6'>
              <h3 className='pb-3'>Charges and Credit Card.</h3>
              <p>
                {intl.formatMessage({id: 'BRAND_NAME'})} charges your credit card each billing
                period Your Service Fees for that period. You must provide{' '}
                {intl.formatMessage({id: 'BRAND_NAME'})} with a valid credit card number belonging
                to you with available credit sufficient to pay the applicable Service Fees. In the
                event that you cancel this credit card or it is otherwise terminated, you must
                immediately provide us with a new valid credit card number.
              </p>
            </div>
            <div className='pb-6'>
              <h3 className='pb-3'>Non-Payment.</h3>
              <p>
                If timely payment is not received or cannot be charged to your credit card for any
                reason, {intl.formatMessage({id: 'BRAND_NAME'})} reserves the right to either
                suspend or terminate your access to the Service and account and terminate this
                Agreement. If {intl.formatMessage({id: 'BRAND_NAME'})}
                receives a cancellation notice from you, you will be obligated to pay balance due on
                your account. You agree that {intl.formatMessage({id: 'BRAND_NAME'})} may charge
                such unpaid fees to your credit card or otherwise bill you for such unpaid fees.
              </p>
            </div>
            <div className='pb-6'>
              <h3 className='pb-3'>Contact and Billing Information.</h3>
              <p>
                It is your responsibility to keep valid contact and billing information on your
                account. {intl.formatMessage({id: 'BRAND_NAME'})} will send out payment issue
                notices regarding your account in accordance with the contact information you have
                provided upon registration. To avoid service interruption due to non-payment issues,
                please ensure all contact information is current under the "My Account" section in{' '}
                {intl.formatMessage({id: 'BRAND_NAME'})}.
              </p>
            </div>
            <div className='pb-6'>
              <h3 className='pb-3'>Termination</h3>
              <p>
                {intl.formatMessage({id: 'BRAND_NAME'})}, in its sole discretion, may terminate your
                password, account or use of the Service and remove and discard any Data within the
                Service if you fail to comply with this Agreement. You may terminate your
                subscription by sending email request to {intl.formatMessage({id: 'BRAND_NAME'})}{' '}
                Support with your {intl.formatMessage({id: 'BRAND_NAME'})} Account Name. If you
                terminate your account at any time, you may be liable for any balance of your
                current subscription, depending on your plan type, and will not receive a refund of
                any part of the subscription rate if you terminate your account during a billing
                cycle or plan period. In addition, {intl.formatMessage({id: 'BRAND_NAME'})} may
                terminate a free account if you do not first log-on within 15 days after
                registration. Upon termination of an account, your right to use such account and the
                Service immediately ceases. {intl.formatMessage({id: 'BRAND_NAME'})}
                {` `}
                shall have no obligation to maintain any Data stored in your account or to forward
                any Data to you or any third party.
              </p>
            </div>
          </div>
        </div>
      </>
    </CSSTransition>
  )
}
