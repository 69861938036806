import React, {useState} from 'react'
import * as Yup from 'yup'
import {CSSTransition} from 'react-transition-group'
import {
  Button,
  ButtonBack,
  InputTextFormik,
  SelectFormik,
} from '../../../../../../../_gori/partials/widgets'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import UseYupValidate from '../../../../../../../_gori/hooks/UseYupValidate'
import {useFormik} from 'formik'
import {OrderType} from '../../../../../orders/core/_const'
import {StoresListing} from '../StoresListing'
import {StorageHelpers, toAbsoluteUrl} from '../../../../../../../_gori/helpers'
import {SettingsConfig} from '../../../../core/_const'
import {useAuth} from '../../../../../auth'

const ConnectAmazon: React.FC = () => {
  const BASE_DOMAIN = process.env.REACT_APP_DOMAIN
  const intl = useIntl()
  const navigate = useNavigate()
  const [isReload, setIsReload] = useState<boolean>(false)
  const {currentCompany} = useAuth()

  const {amazonYup} = UseYupValidate()
  const validationSchema = Yup.object().shape({
    store_name: amazonYup.storeName,
    region: amazonYup.region,
  })

  const initialValues = {
    store_type: OrderType.AMAZON,
    store_name: '',
    region: {
      url: '',
      code: '',
    },
  }

  const generateState = () => {
    const array = new Uint8Array(16)
    window.crypto.getRandomValues(array)
    return Array.from(array, (byte) => ('0' + byte.toString(16)).slice(-2)).join('')
  }

  const onSubmit = async () => {
    const state = generateState()
    const timestamp = Date.now()
    const stateToken = btoa(`${state}:${timestamp}`)
    const storeName = formik.values.store_name
    const region = JSON.stringify(formik.values.region)
    // Store state in localStorage for later validation
    localStorage.setItem('auth_state', stateToken)
    localStorage.setItem('store_name', storeName)
    localStorage.setItem('region', region)
    StorageHelpers.setItemCookies('slug', currentCompany?.slug, {
      path: '/',
      domain: BASE_DOMAIN?.split(':')[0],
    })
    const consentUrl =
      formik.values.region.url +
      '/apps/authorize/consent' +
      '?application_id=' +
      SettingsConfig.AMAZON.APPLICATION_ID +
      '&state=' +
      stateToken +
      '&version=beta'
    window.open(consentUrl, '_blank')
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  })

  const handleResetForm = () => {
    formik.resetForm()
  }

  const goBack = () => {
    navigate('/settings/connections')
  }

  return (
    <>
      <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
        <div className={'card p-10'}>
          <div className='card-title'>
            <div className={'d-flex gap-4 align-items-center'}>
              <ButtonBack label={intl.formatMessage({id: 'BACK'})} event={goBack} />
              <span className='fw-bolder text-dark fs-3'>
                {intl.formatMessage({id: 'CONNECT_TO'})} {intl.formatMessage({id: 'AMAZON'})}
              </span>
            </div>
          </div>
          <div className='card-body d-flex justify-content-center'>
            <div className='col-md-8'>
              <InputTextFormik
                labelClassName='col-md-4 col-form-label mt-5'
                required
                label={'Amazon ' + intl.formatMessage({id: 'STORE_NAME'})}
                formik={formik}
                name={'store_name'}
              />
              <SelectFormik
                className='col-12 col-sm-12'
                labelClassName='col-4 col-sm-3 col-form-label'
                label={'Amazon ' + intl.formatMessage({id: 'REGION'})}
                required
                options={SettingsConfig.AMAZON.REGION}
                formik={formik}
                name={'region'}
              />
              <div className='text-end'>
                <Button
                  className='btn btn-primary mt-7'
                  label={intl.formatMessage({id: 'CONNECT'})}
                  loadingText={intl.formatMessage({id: 'CONNECT'})}
                  loading={formik.isSubmitting}
                  disabled={formik.isSubmitting || !formik.isValid}
                  event={formik.submitForm}
                />
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
      <StoresListing isReload={isReload} setIsReload={setIsReload} storeType={OrderType.AMAZON} />
    </>
  )
}

export {ConnectAmazon}
