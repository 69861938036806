import {useCallback, useEffect, useRef} from 'react'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import {CSSTransition} from 'react-transition-group'
import {toAbsoluteUrl} from '../../../_gori/helpers'
import {Footer} from './components/Footer'
import {Header} from './components/Header'
import {HowItWorks} from './components/HowItWorks'
import AuthService from '../../modules/auth/core/_requests'
import {StartFreeToday} from './components/StartFreeToday'
import {Discounts} from './components/Discounts'
import {CalculateLabels} from './components/CalculateLabels'
import {Security} from './components/Security'

const baseDomain = process.env.REACT_APP_DOMAIN

export function LandingPage() {
  const intl = useIntl()

  const animatedCardsRef = useRef<HTMLUListElement>(null)
  const dashboardGraphicRef = useRef<HTMLDivElement>(null)
  const toolsGraphicRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const protocol = document.location.protocol
    const host = document.location.host?.split(':')?.[0]
    const domain = baseDomain?.split(':')?.[0]
    const subDomain = host.split(`.${domain}`).filter((item) => item !== domain)?.[0]
    if (subDomain) {
      let urlRedirect = `${protocol}//${baseDomain}/landing`
      document.location.replace(urlRedirect)
    }
  }, [])

  const getVersion = useCallback(async () => {
    try {
      await AuthService.getVersion()
    } catch (error) {
      console.log(error)
    }
  }, [])

  useEffect(() => {
    getVersion()
  }, [getVersion])

  const flourishCards = useCallback(async () => {
    const cards = animatedCardsRef.current

    function handleIntersect(entries: IntersectionObserverEntry[], observer: IntersectionObserver) {
      entries.forEach((entry) => {
        if (entry.isIntersecting && cards) {
          cards.classList.add('flourish')
        } else if (!entry.isIntersecting && cards) {
          cards.classList.remove('flourish')
        }
      })
    }

    const observer = new IntersectionObserver(handleIntersect, {
      root: null,
      rootMargin: '-50% 0px -50% 0px',
      threshold: 0,
    })

    if (cards) {
      observer.observe(cards)
    }

    return () => {
      observer.disconnect()
    }
  }, [])

  const dashboardPopIn = useCallback(async () => {
    const dashboard = dashboardGraphicRef.current

    function handleEntryToView(
      entries: IntersectionObserverEntry[],
      observer: IntersectionObserver
    ) {
      entries.forEach((entry) => {
        if (entry.isIntersecting && dashboard) {
          dashboard.classList.add('fade-in')
        }
      })
    }

    const observer = new IntersectionObserver(handleEntryToView, {
      root: null,
      rootMargin: '0px 0px 0px 0px',
      threshold: 0,
    })

    if (dashboard) {
      observer.observe(dashboard)
    }

    return () => {
      observer.disconnect()
    }
  }, [])

  const toolsPopIn = useCallback(async () => {
    const tools = toolsGraphicRef.current

    function handleEntryToView(
      entries: IntersectionObserverEntry[],
      observer: IntersectionObserver
    ) {
      entries.forEach((entry) => {
        if (entry.isIntersecting && tools) {
          tools.classList.add('fade-in')
        }
      })
    }

    const observer = new IntersectionObserver(handleEntryToView, {
      root: null,
      rootMargin: '0px 0px 0px 0px',
      threshold: 0,
    })

    if (tools) {
      observer.observe(tools)
    }

    return () => {
      observer.disconnect()
    }
  }, [])

  useEffect(() => {
    flourishCards()
    dashboardPopIn()
    toolsPopIn()
  }, [flourishCards, dashboardPopIn, toolsPopIn])

  return (
    <>
      <Header />
      <CSSTransition appear in timeout={300} classNames='fade' unmountOnExit>
        <div className='landing-page bg-white'>
          <section className='landing-hero gradient-bg'>
            <div className='container'>
              <h1
                className='mb-12px fw-boldest text-center text-primary lh-sm'
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({id: 'LANDING_H1'}),
                }}
              ></h1>
              <p
                className='text-center h1-sub-copy mw-1116px my-0 mx-auto'
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({id: 'LANDING_SUBTITLE_1'}),
                }}
              ></p>

              <p
                className='text-center h1-sub-copy mw-1116px my-0 mx-auto'
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({id: 'LANDING_SUBTITLE_1a'}),
                }}
              ></p>

              <p
                className='text-center h1-sub-copy mw-1116px mt-0 mx-auto mb-22'
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({id: 'LANDING_SUBTITLE_1b'}),
                }}
              ></p>

              <Discounts />

              <Link
                to='/auth/registration'
                className='btn btn-outline btn-primary hover-orange rounded-pill text-center d-block w-fit-content mx-auto mb-12px'
              >
                <img
                  className='me-3'
                  src={toAbsoluteUrl('/media/gori/landing/store.svg')}
                  alt='store-icon'
                />
                {intl.formatMessage({id: 'LANDING_BUTTON_1'})}
              </Link>

              <p className='fs-4 text-center mx-auto mb-0'>
                <img src={toAbsoluteUrl('/media/gori/landing/check_circle_gray.svg')} alt='check' />{' '}
                {intl.formatMessage({id: 'LANDING_FOOTNOTE'})}
              </p>

              <div className='sticky-device-container'>
                <div className='device-container' id='sticky-device'>
                  <div className='device sticky-device'>
                    <img
                      className='deviceBorder mobile-only'
                      src='https://gori-ai.s3.us-west-2.amazonaws.com/shipbae-assets/tablet.webp'
                      alt='tablet-frame'
                    />
                    <img
                      className='deviceBorder desktop-only'
                      src='https://gori-ai.s3.us-west-2.amazonaws.com/shipbae-assets/laptop.webp'
                      alt='laptop-frame'
                    />
                    <video
                      src='https://gori-ai.s3.us-west-2.amazonaws.com/shipbae-assets/shipbae-landing-video.mp4'
                      className=''
                      muted
                      loop
                      autoPlay
                    ></video>
                  </div>
                </div>

                <p className='scrolling-text first left light'>
                  {intl.formatMessage({id: 'LANDING_FIXED_1'})}
                </p>
                <p className='scrolling-text second right dark'>
                  {intl.formatMessage({id: 'LANDING_FIXED_2'})}
                  <br />
                  <span>{intl.formatMessage({id: 'LANDING_FIXED_2_SUB'})}</span>
                </p>
                <p className='scrolling-text third right dark'>
                  {intl.formatMessage({id: 'LANDING_FIXED_3'})}
                  <br />
                  <span
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({id: 'LANDING_FIXED_3_SUB'}),
                    }}
                  ></span>
                </p>
                <p className='scrolling-text last fourth center text-darkTeal2'>
                  {intl.formatMessage({id: 'LANDING_FIXED_4'})}
                </p>
              </div>
            </div>
          </section>

          <section className='features gradient-bg2'>
            <div className='container'>
              <h2 className='mb-30 fw-boldest text-center lh-sm custom-h2 light'>
                {intl.formatMessage({id: 'LANDING_H2_1_1'})}
                <br />
                <span>{intl.formatMessage({id: 'LANDING_H2_1_2'})}</span>
                <br />
                {intl.formatMessage({id: 'LANDING_H2_1_3'})}
              </h2>

              <ul
                className='list-unstyled animated-cards mb-30'
                id='animated-cards'
                ref={animatedCardsRef}
              >
                <li className='card one'>
                  <p className='eyebrow'>{intl.formatMessage({id: 'LANDING_CARDS_EYEBROW_1'})}</p>
                  <p className='title'>{intl.formatMessage({id: 'LANDING_CARDS_TITLE_1'})}</p>
                  <img
                    src={toAbsoluteUrl('/media/gori/landing/dashboard-graphic.webp')}
                    alt='dashboard graphic'
                  />
                </li>
                <li className='card two'>
                  <p className='eyebrow'>{intl.formatMessage({id: 'LANDING_CARDS_EYEBROW_2'})}</p>
                  <p className='title'>{intl.formatMessage({id: 'LANDING_CARDS_TITLE_2'})}</p>
                  <img
                    src={toAbsoluteUrl('/media/gori/landing/oms-graphic.webp')}
                    alt='oms graphic'
                  />
                </li>
                <li className='card three'>
                  <p className='eyebrow'>{intl.formatMessage({id: 'LANDING_CARDS_EYEBROW_3'})}</p>
                  <p className='title'>{intl.formatMessage({id: 'LANDING_CARDS_TITLE_3'})}</p>
                  <img
                    src={toAbsoluteUrl('/media/gori/landing/label-graphic.webp')}
                    alt='label graphic'
                  />
                </li>
                <li className='card four'>
                  <p className='eyebrow'>{intl.formatMessage({id: 'LANDING_CARDS_EYEBROW_4'})}</p>
                  <p className='title'>{intl.formatMessage({id: 'LANDING_CARDS_TITLE_4'})}</p>
                  <img
                    src={toAbsoluteUrl('/media/gori/landing/claims-graphic.webp')}
                    alt='claims graphic'
                  />
                </li>
                <li className='card five'>
                  <p className='eyebrow'>{intl.formatMessage({id: 'LANDING_CARDS_EYEBROW_5'})}</p>
                  <p className='title'>{intl.formatMessage({id: 'LANDING_CARDS_TITLE_5'})}</p>
                  <img
                    src={toAbsoluteUrl('/media/gori/landing/reports-graphic.webp')}
                    alt='reports graphic'
                  />
                </li>
              </ul>
            </div>

            <div className='container why'>
              <h2 className='fw-boldest text-center lh-sm custom-h2'>
                {intl.formatMessage({id: 'LANDING_H2_2'})}
              </h2>

              <ul className='list-unstyled cards'>
                <li className='card d-flex flex-column gap-10'>
                  <div className='d-flex justify-content-center align-items-center exception w-100'>
                    <img
                      className=''
                      src={toAbsoluteUrl('/media/gori/landing/store-connections.webp')}
                      alt='reports graphic'
                    />

                    <div className='pulsing-animation'>
                      <div className='ring one'></div>
                      <div className='ring two'></div>
                      <div className='ring three'></div>
                      <div className='ring four'></div>
                      <div className='ring five'></div>
                      <div className='ring six'></div>
                      <p>{intl.formatMessage({id: 'LANDING_CARDS_2_TITLE_ANIMATED'})}</p>
                    </div>

                    <div className='w-100 integrate-inner'>
                      <p className='title'>
                        <span>{intl.formatMessage({id: 'LANDING_CARDS_2_TITLE_BLUE_1'})}</span>
                        <br />
                        {intl.formatMessage({id: 'LANDING_CARDS_2_TITLE_BLACK_1'})}
                      </p>

                      <ul className='list-unstyled d-flex flex-row flex-wrap'>
                        <Link to='/integrations/shipstation'>
                          <li className='icons'>
                            <img
                              className='cog'
                              src={toAbsoluteUrl('/media/gori/landing/cog.webp')}
                              alt=''
                            />
                          </li>
                        </Link>
                        <Link to='/integrations/shopify'>
                          <li className='icons'>
                            <img
                              className='shopify'
                              src={toAbsoluteUrl('/media/gori/landing/shopify.webp')}
                              alt=''
                            />
                          </li>
                        </Link>
                        <Link to='/integrations/walmart'>
                          <li className='icons'>
                            <img
                              className='walmart'
                              src={toAbsoluteUrl('/media/gori/landing/walmart.webp')}
                              alt=''
                            />
                          </li>
                        </Link>
                        <Link to='/integrations/tiktok'>
                          <li className='icons'>
                            <img
                              className='tiktok'
                              src={toAbsoluteUrl('/media/gori/landing/tiktok.svg')}
                              alt=''
                            />
                          </li>
                        </Link>
                      </ul>

                      <ul className='list-unstyled d-flex flex-row flex-wrap'>
                        <Link to='/integrations/amazon'>
                          <li className='icons gray coming-soon'>
                            <img
                              className='coming-soon-img'
                              src={toAbsoluteUrl('/media/gori/landing/coming-soon.webp')}
                              alt=''
                            />

                            <img
                              className='amazon'
                              src={toAbsoluteUrl('/media/gori/landing/amazon.svg')}
                              alt=''
                            />
                          </li>
                        </Link>
                        <Link to='/integrations/ebay'>
                          <li className='icons gray coming-soon'>
                            <img
                              className='coming-soon-img'
                              src={toAbsoluteUrl('/media/gori/landing/coming-soon.webp')}
                              alt=''
                            />
                            <img
                              className='ebay'
                              src={toAbsoluteUrl('/media/gori/landing/ebay.svg')}
                              alt=''
                            />
                          </li>
                        </Link>
                        <li className='icons'>
                          <img
                            className='plus'
                            src={toAbsoluteUrl('/media/gori/landing/plus.svg')}
                            alt=''
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                  <p>
                    <span className='d-block mb-2'>
                      {intl.formatMessage({id: 'LANDING_CARDS_2_TITLE_COPY_1'})}
                    </span>
                    <span className='d-block mb-2'>
                      {intl.formatMessage({id: 'LANDING_CARDS_2_TITLE_COPY_1a'})}
                    </span>
                    <Link
                      className='fw-bolder text-decoration-underline'
                      to={intl.formatMessage({id: 'LANDING_CARDS_2_TITLE_LINK_1'})}
                    >
                      {intl.formatMessage({id: 'LANDING_CARDS_2_TITLE_LINK_TEXT_1'})}
                    </Link>
                    !
                  </p>
                </li>
                <li className='card d-flex justify-content-center align-items-center'>
                  <img
                    className=''
                    src={toAbsoluteUrl('/media/gori/landing/rates.webp')}
                    alt='rates graphic'
                  />

                  <div className='w-100'>
                    <p className='title'>
                      <span>{intl.formatMessage({id: 'LANDING_CARDS_2_TITLE_BLUE_2'})}</span>
                      <br />
                      {intl.formatMessage({id: 'LANDING_CARDS_2_TITLE_BLACK_2'})}
                    </p>

                    <ul className='list-unstyled d-flex flex-row flex-wrap'>
                      <li className='icons longer w-100'>
                        <img
                          className='got'
                          src={toAbsoluteUrl('/media/gori/landing/got.webp')}
                          alt=''
                        />
                      </li>
                      <li className='icons'>
                        <img
                          className='usps'
                          src={toAbsoluteUrl('/media/gori/landing/usps.webp')}
                          alt=''
                        />
                      </li>
                      <li className='icons'>
                        <img
                          className='ups'
                          src={toAbsoluteUrl('/media/gori/landing/ups.webp')}
                          alt=''
                        />
                      </li>
                      <li className='icons'>
                        <img
                          className='dhl'
                          src={toAbsoluteUrl('/media/gori/landing/dhl.svg')}
                          alt=''
                        />
                      </li>
                      <li className='icons'>
                        <img
                          className='fedex'
                          src={toAbsoluteUrl('/media/gori/landing/fedex.svg')}
                          alt=''
                        />
                      </li>
                    </ul>

                    <p
                      dangerouslySetInnerHTML={{
                        __html: intl.formatMessage({id: 'LANDING_CARDS_2_TITLE_COPY_2'}),
                      }}
                    ></p>
                  </div>
                </li>
                <li className='card d-flex justify-content-center align-items-center'>
                  <div
                    className='d-flex flex-column justify-content-center align-items-center dashboard-graphic'
                    id='dashboard-graphic'
                    ref={dashboardGraphicRef}
                  >
                    <div className='d-flex flex-row justify-content-space-between align-items-center'>
                      <img
                        className=''
                        src={toAbsoluteUrl('/media/gori/landing/wallet.webp')}
                        alt='wallet graphic'
                      />
                      <img
                        className=''
                        src={toAbsoluteUrl('/media/gori/landing/paid.webp')}
                        alt='paid graphic'
                      />
                      <img
                        className=''
                        src={toAbsoluteUrl('/media/gori/landing/trends.webp')}
                        alt='trends graphic'
                      />
                    </div>
                    <img
                      className=''
                      src={toAbsoluteUrl('/media/gori/landing/map.webp')}
                      alt='map graphic'
                    />
                  </div>

                  <div className='w-100'>
                    <p className='title'>
                      <span>{intl.formatMessage({id: 'LANDING_CARDS_2_TITLE_BLUE_3'})}</span>
                      <br />
                      {intl.formatMessage({id: 'LANDING_CARDS_2_TITLE_BLACK_3'})}
                    </p>

                    <p>{intl.formatMessage({id: 'LANDING_CARDS_2_TITLE_COPY_3'})}</p>
                  </div>
                </li>
                <li className='card d-flex justify-content-center align-items-center'>
                  <div
                    className='d-flex flex-row flex-wrap justify-content-center align-items-center tools-graphic'
                    id='tools-graphic'
                    ref={toolsGraphicRef}
                  >
                    <img
                      className=''
                      src={toAbsoluteUrl('/media/gori/landing/filter.webp')}
                      alt='filter graphic'
                    />
                    <img
                      className=''
                      src={toAbsoluteUrl('/media/gori/landing/batches.webp')}
                      alt='batches graphic'
                    />
                    <img
                      className=''
                      src={toAbsoluteUrl('/media/gori/landing/transactions.webp')}
                      alt='transactions graphic'
                    />
                    <img
                      className=''
                      src={toAbsoluteUrl('/media/gori/landing/claims.webp')}
                      alt='claims graphic'
                    />
                  </div>

                  <div className='w-100'>
                    <p className='title'>
                      <span>{intl.formatMessage({id: 'LANDING_CARDS_2_TITLE_BLUE_4'})}</span>
                      <br />
                      {intl.formatMessage({id: 'LANDING_CARDS_2_TITLE_BLACK_4'})}
                    </p>

                    <ul className='list-unstyled d-flex flex-row flex-wrap'>
                      <li className='icons'>
                        <img
                          className='filter-icon'
                          src={toAbsoluteUrl('/media/gori/landing/filter-icon.svg')}
                          alt=''
                        />
                      </li>
                      <li className='icons'>
                        <img
                          className='batch-icon'
                          src={toAbsoluteUrl('/media/gori/landing/batch-icon.svg')}
                          alt=''
                        />
                      </li>
                      <li className='icons'>
                        <img
                          className='download-icon'
                          src={toAbsoluteUrl('/media/gori/landing/download-icon.svg')}
                          alt=''
                        />
                      </li>
                      <li className='icons'>
                        <img
                          className='card-icon'
                          src={toAbsoluteUrl('/media/gori/landing/card-icon.svg')}
                          alt=''
                        />
                      </li>
                    </ul>

                    <p>{intl.formatMessage({id: 'LANDING_CARDS_2_TITLE_COPY_4'})}</p>
                  </div>
                </li>
              </ul>

              <Link
                to='/auth/registration'
                className='btn btn-outline btn-primary hover-orange rounded-pill text-center d-block w-fit-content mx-auto'
              >
                <img
                  className='me-3'
                  src={toAbsoluteUrl('/media/gori/landing/store.svg')}
                  alt='store-icon'
                />
                {intl.formatMessage({id: 'LANDING_BUTTON_1'})}
              </Link>
            </div>
          </section>

          <CalculateLabels />

          <HowItWorks />

          <Security />

          <section className='whats-new overflow-x-hidden'>
            <div className='container d-flex align-items-center justify-content-between'>
              <div className='copy-container'>
                <h2
                  className='mb-6 fw-boldest custom-h2 bigger pt-5'
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({id: 'LANDING_H2_4'}),
                  }}
                ></h2>
                <h3 className='fw-boldest lh-sm custom-h3 mb-12'>
                  {intl.formatMessage({id: 'LANDING_SUBTITLE_2'})}
                </h3>
                <a
                  href='https://gori.ai/shipbae-update'
                  target='_blank'
                  rel='noreferrer'
                  className='desktop-only pretendard btn btn-outline custom-rocket-btn rounded-pill text-center d-block w-fit-content'
                >
                  {intl.formatMessage({id: 'LANDING_BUTTON_2'})}
                </a>
              </div>
              <div className='device-container'>
                <div className='device tablet'>
                  <img
                    className='deviceBorder'
                    src='https://gori-ai.s3.us-west-2.amazonaws.com/shipbae-assets/tablet.webp'
                    alt=''
                  />
                  <video
                    src='https://gori-ai.s3.us-west-2.amazonaws.com/shipbae-assets/shipbae-update.mov'
                    muted
                    loop
                    autoPlay
                  ></video>
                </div>
              </div>
              <a
                href='https://gori.ai/shipbae-update'
                target='_blank'
                rel='noreferrer'
                className='mobile-only mt-15 pretendard btn btn-outline custom-rocket-btn rounded-pill text-center d-block w-fit-content'
              >
                {intl.formatMessage({id: 'LANDING_BUTTON_2'})}
              </a>
            </div>
          </section>

          <StartFreeToday />
        </div>
      </CSSTransition>
    </>
  )
}
