import React, {useCallback, useEffect, useState} from 'react'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {CSSTransition} from 'react-transition-group'
import {LoadingPage} from '../../../../../../../_gori/helpers/components/LoadingPage'
import {toast} from 'react-toastify'
import {useIntl} from 'react-intl'
import SettingsService from '../../../../core/_requests'
import {OrderType} from '../../../../../orders/core/_const'

const VerifyAmazon: React.FC = () => {
  const [queryParams] = useSearchParams()
  const navigate = useNavigate()
  const intl = useIntl()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const state = queryParams.get('state')
  const authState = localStorage.getItem('auth_state')
  const spApiOAuthCode = queryParams.get('spapi_oauth_code')
  const sellingPartnerId = queryParams.get('selling_partner_id')
  const storeName = localStorage.getItem('store_name')
  const marketplace_id = JSON.parse(localStorage.getItem('region') as string).marketplace_id

  const isValidStateToken = (receivedToken, storedState) => {
    const [receivedStateToken, receivedTimestamp] = atob(receivedToken).split(':')
    const [storedStateToken, storedTimestamp] = atob(storedState).split(':')
    if (
      storedStateToken !== receivedStateToken ||
      parseInt(storedTimestamp) !== parseInt(receivedTimestamp)
    ) {
      return false
    }

    // Check if token is expired (5 minutes)
    const currentTime = Date.now()
    return currentTime - parseInt(receivedTimestamp) <= 5 * 60 * 1000
  }

  const connectAmazon = useCallback(async () => {
    setIsLoading(true)
    if (isValidStateToken(authState, state)) {
      try {
        const res = await SettingsService.connect({
          store_type: OrderType.AMAZON,
          store_name: storeName,
          spapi_oauth_code: spApiOAuthCode,
          selling_partner_id: sellingPartnerId,
          marketplace_id: marketplace_id,
        })
        if (res) {
          toast.success(intl.formatMessage({id: 'CONNECTIONS_SUCCESSFUL'}))
        }
      } catch (error) {
        toast.error(intl.formatMessage({id: 'CONNECTIONS_FAILED'}))
      } finally {
        setIsLoading(false)
      }
    } else {
      toast.error(intl.formatMessage({id: 'AUTHORIZATION_TIME_OUT'}))
    }

    // redirect to Amazon Connections page
    navigate('/settings/connections/amazon')
  }, [storeName, spApiOAuthCode, sellingPartnerId, intl, navigate])

  useEffect(() => {
    connectAmazon()
  }, [connectAmazon])

  return (
    <CSSTransition appear in timeout={100} classNames='fade' unmountOnExit>
      <LoadingPage />
    </CSSTransition>
  )
}

export {VerifyAmazon}
