import React, {useEffect} from 'react'
import {useSearchParams} from 'react-router-dom'
import {CSSTransition} from 'react-transition-group'
import {LoadingPage} from '../../../_gori/helpers/components/LoadingPage'
import {StorageHelpers} from '../../../_gori/helpers'

const GlobalAmazonVerifyPage: React.FC = () => {
  const slug = StorageHelpers.getItemCookies('slug')
  const [searchParams] = useSearchParams()
  const BASE_DOMAIN = process.env.REACT_APP_DOMAIN

  useEffect(() => {
    if (slug) {
      const queryString: string[] = []
      searchParams.forEach((value, key) => {
        const param = key + '=' + value
        queryString.push(param.toString())
      })

      const protocol = document.location.protocol
      const url =
        protocol +
        '//' +
        slug +
        '.' +
        BASE_DOMAIN +
        '/settings/connections/amazon/verify?' +
        queryString.join('&')

      document.location.replace(url)
    }
  })

  return (
    <CSSTransition appear in timeout={100} classNames='fade' unmountOnExit>
      <LoadingPage />
    </CSSTransition>
  )
}

export {GlobalAmazonVerifyPage}
