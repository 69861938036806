import {Suspense} from 'react'
import {LayoutProvider, LayoutSplashScreen} from '../../core'
import {I18nProvider} from '../../../i18n/i18nProvider'

import {Outlet} from 'react-router-dom'
import {Header} from '../../../../app/pages/landing/components/Header'
import {Footer} from '../../../../app/pages/landing/components/Footer'
import CookieBanner from '../../../../app/modules/common/modals/CookieModal'
import ScrollToTop from '../../../../app/modules/help-center/components/common/ScrollToTop'

const InformationLayout = () => {
  return (
    <>
      <CookieBanner />
      <ScrollToTop />
      <Suspense fallback={<LayoutSplashScreen />}>
        <I18nProvider>
          <LayoutProvider>
            <Header />
            <Outlet />
            <Footer />
          </LayoutProvider>
        </I18nProvider>
      </Suspense>
    </>
  )
}

export {InformationLayout}
